@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-VariableFont.ttf');
}

html,
body {
  background-color: #000;
  width: 100%;
  height: 100%;
  font-family: 'Inter';
  font-weight: 400;
  overscroll-behavior: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: pan-y pan-x;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown a:hover {
  background-color: #ddd;
}

.series {
  width: auto;
  margin: auto;
  max-width: 100%;
}

.App {
  overflow: hidden;
}

@media not all and (max-width: 768px), (orientation: portrait) {
  .App {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 769px) and (orientation: landscape) {
  .App {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

:root {
  --color-complete: #7486ae;
  --color-warning: #ff786c;
  --color-inconsistent: #475569;
  --info-color: #77a3f9;
  --white-color: #fff;
  --gray-color: rgba(241, 245, 249, 0.4);
  --gray-hover-color: rgba(241, 245, 249, 0.5);
  --black-color: #1a1a1a;
}

.header-default-properties {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 10px;
}

.header-item-style {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0px 16px;
  min-height: 38px;
}
