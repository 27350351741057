.reviewed-by-container {
  display: flex;
  padding: 5%;
  font-size: 12px;
  color: white;
  background-color: #141414;
  gap: 20px;
  justify-content: space-between;
}

.reviewed-by-text {
  opacity: 0.5;
}

.reviewed-by-date {
  opacity: 0.4;
}
