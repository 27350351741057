
.timeline-item {
  display: flex;
  flex-direction: column;
}

.timeline-date {
  font-size: 12px;
  text-align: center;
}

.timeline-title-container {
  justify-content: space-between;
  color: white;
}

.show-reviewed-by-container {
  display: flex;
  gap: 10px;
}

.timeline-header {
  position: sticky;
  top: 0;
  background-color: black;
  z-index: 10;
}

.timeline-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.2)
  );
}

.timeline-scrollable-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 10px;
  padding: 0px 16px;
  color: white;
  margin-top: 12px;
}

.timeline-date-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.no-data-timeline-text {
  color: white;
  font-size: 12px;
  padding: 16px;
}
