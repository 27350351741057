.image-dropdown-item {
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: space-between;
}
  
.image-dropdown-item .processing-icon,
.image-dropdown-item .warning-icon {
  max-width: 18px;
  max-height: 18px;
}

.dropdown-item-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  margin-right: 6px;
}