.MuiAccordionSummary-root{
  height: 46px !important;
  min-height: 46px !important;
}

.MuiAccordionDetails-root {
  padding: 1rem 12px !important;
  padding-top: 0px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 100% !important;
  height: 46px !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.accordion-icon {
  width: 16px !important;
  height: 16px !important;
  color: #fff !important;
}

.accordion-header {
  display: flex !important;
  gap: 8px !important;
  font-size: 12px !important;
}