.spinner-container {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.spinner {
  position: relative;
  width: 78px;
  height: 78px;
  border: 5px solid #1a1a1a;
  border-top-color: #77A3F9 ;
  border-radius: 50%;
  animation: spin 2.5s linear infinite;
}

.loading-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 19px;
  font-weight: 500;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}