/*
  - `width: 100%` and `height: 100%`: These ensure the image container takes up the full width and height of its parent.
  - `object-fit: contain`: This property ensures that the image maintains its original aspect ratio while fitting within its container.
  - `position: absolute`: This positions the image absolutely relative to its nearest positioned ancestor, in this case, the viewer component.
  - `display: flex`: This sets the container to use flexbox layout.
  - `align-items: center` and `justify-content: center`: These center the content both vertically and horizontally within the flex container.
*/
.nifti-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
