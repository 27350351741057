
.slider-menu-container {
  background-color: var(--black-color);
  display: flex;
  align-items: center;
  flex: 1;
  height: 36px;
  margin: 0 10px;
  padding: 0 10px;
  border-radius: 70px;
}

.menu-text {
  color: var(--info-color);
  font-size: 12px;
}

.slider-container {
  display: flex;
  flex: 1;
  align-items: center;
}

.slider-wrapper {
  flex: 1;
}

.slider-value {
  color: #fff;
  font-size: 12px;
  margin-right: 15px;
}

.cross-icon {
  cursor: pointer;
  display: flex;
}
