.MuiAccordionDetails-root.accordion-details {
  padding: 15px !important;
  padding-top: 0px !important;
}

.MuiAccordionSummary-root.Mui-expanded.results-accordion-header {
  padding-top: 0 !important;
}

.MuiAccordion-root.results-accordion-header-warning {
  border-left: 2px solid #ff786c;
}

.MuiAccordion-root.results-accordion-header-complete {
  border-left: 2px solid #7486ae;
}

span.results-accordion-header-warning {
  color: #ff786c !important;
}

span.results-accordion-header-complete {
  color: #7486ae !important;
}

.MuiAccordionSummary-root.Mui-disabled {
  opacity: 1 !important;
  background-color: rgb(25, 25, 25) !important;
  border-radius: 4px;
  border-left: 2px solid #A6A6A6;
}

.MuiSvgIcon-root {
  height: 18px !important;
  width: 18px !important;
  display: flex !important;
  align-items: center !important;
}

.MuiAccordion-root.results-accordion-header-warning {
  border-left: 2px solid #ff786c;
}

.MuiAccordion-root.results-accordion-header {
  border-left: 2px solid #7486ae;
}

.icon-container {
  height: 18px !important;
  display: flex;
  align-items: center;
  flex: 1;
  width: 18px !important;
}

.accordion-header-content {
  display: flex;
  align-items: center;
  gap: 6px;
}