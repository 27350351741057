.screen-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #ffffff;
  padding: 15%;
  text-align: center;
  box-sizing: border-box;
}

.screen-message-header {
  margin-top: 0;
  font-size: 32px;
  font-weight: normal;
}

.screen-message-message {
  font-size: 16px;
}

.action-button {
  background-color: rgba(119, 163, 249, 0.6);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
}