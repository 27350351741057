.switch {
  --width: 30px;
  --height: 16px;
  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  cursor: pointer;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  inset: 0;
  background-color: #a6b2cb;
  transition: 0.2s;
  border-radius: calc(var(--height) / 2);
}

.toggle-slider:before {
  content: '';
  position: absolute;
  height: calc(var(--height) - 2px);
  width: calc(var(--height) - 2px);
  left: 1px;
  bottom: 1px;
  background-color: white;
  transition: 0.2s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #ff786c;
}

input:checked + .toggle-slider:before {
  transform: translateX(calc(var(--width) - var(--height)));
}
