.timeline-card-container {
  display: flex;
  padding: 5%;
  font-size: 12px;
  flex-direction: column;
  color: white;
  background-color: #141414;
  min-height: fit-content;
  gap: 25px;
}

.first-column {
  min-width: 15%;
  display: flex;
  position: relative;
}

.second-column {
  flex: 1;
}

.timeline-card-row {
  display: flex;
}

.image-analysis-info-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 10px;
}

.opacity-zero-point-five {
  opacity: 0.5;
}

.suspected-text {
  color: var(--color-warning);
}

.timeline-icon {
  position: absolute;
  top: -50%;
  right: 30%;
}

.headline-result {
  font-size: 12px;
}

.blue-text-image-analysis {
  color: var(--color-complete);
}

.status-container {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}