.selection-menu {
  background-color: #333;
  color: #fff;
  border-radius: 70px;
  z-index: 1;
  height: 36px;
  display: flex;
  align-items: center;
  margin: 0 10px;
  justify-content: space-between;
  font-size: 10px;
  background-color: #1a1a1a;
}

.selection-icon {
  border-radius: 50px;
  display: flex;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.selection-button {
  cursor: pointer;
  font-weight: 500;
  font-size: 10px;
  padding: 8px;
  gap: 8px;
  border-radius: 41px;
  border: 1px solid transparent; /* Ensure there's an initial border to transition from */
  color: #fff; /* Initial color */
  transition: color 300ms ease-in-out, border-color 300ms ease-in-out;
  display: flex;
  flex: 1;
  justify-content: center;
}
.active {
  border: 1px solid #77a3f9;
  color: #77a3f9;
}

.inactive {
  color: #fff;
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;
}