.nifti-viewer {
  display: flex;
  position: relative;
  flex: 1;
}

.menu-spacing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.top-menu-container {
  margin-top: 4%;
  z-index: 1;
  min-width: fit-content
}

.bottom-menu-container {
  margin-bottom: 2%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.none {
  display: none;
}

.add-margin-bottom {
  margin-bottom: 5%;
}

