.help-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #000000;
  color: #ffffff;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15%;
}

.menu-button,
.help-button,
.urgent-button {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  text-align: left;
}

h2 {
  font-size: 36px;
  margin-bottom: 2rem;
  align-self: flex-start;
}

.help-button {
  margin-bottom: 1rem;
  background-color: rgba(119, 163, 249, 0.6);
}

.urgent-text {
  align-self: flex-start;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.urgent-button {
  background-color: rgba(249, 119, 119, 0.6);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.phone-number {
  margin-top: 0.5rem;
}