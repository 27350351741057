/* Z index is set to 1 to ensure that the top bar is always on top of the viewer */

.top-bar {
  color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-width: 100%;
}

.space-buttons {
  display: flex;
  justify-content: space-between;
}

.top-bar-button {
  background-color: #1a1a1a;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.top-bar-left-group {
  display: flex;
  gap: 14px;
  margin-left: 10px;
}

.top-bar-right {
  margin-right: 10px;
  opacity: 0.8;
}

.active-canvas-menu-icon {
  color: var(--info-color);
  border: 1px solid var(--info-color);
  transition: color 0.2s ease-in-out, 
              border-color 0.2s ease-in-out,
              background-color 0.2s ease-in-out;
}

.disabled-canvas-menu-icon {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
  touch-action: none;
}

.hidden-canvas-menu-icon {
  display: none;
}
