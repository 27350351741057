.skeleton-full-screen {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  height: 100vh;
  gap: 1rem;
  padding: 4rem 1rem;
  box-sizing: border-box;
  flex: 1 !important;
}

.skeleton-items {
  background-color: #424242 !important;
  border-radius: 4px !important;

}

.small-skeleton {
  height: 7% !important;
}

.selection-item-large {
  display: flex !important;
  flex: 1 !important;
}

