.font-weight-500 {
  font-weight: 500;
}

.bottom-menu {
  color: white;
  display: flex;
  justify-content: space-between;
}

.disclaimer {
  justify-content: right;
}

.bottom-nav {
  font-size: 10px;
  margin: 10px;
  display: flex;
  opacity: 0.8;
  width: 100%;
}