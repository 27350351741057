.slider-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  z-index: 2;
  min-height: 40px;
}

#volume-slider {
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 6px;
  z-index: 1;
}

.rotate-180 {
  transform: rotate(180deg);
}

.arrow-icon {
  cursor: pointer;
  display: flex;
  z-index: 3;
  padding: 8px;
}

.arrow-icon:first-child {
  margin-right: 15px;
}

.arrow-icon:last-child {
  margin-left: 15px;
}

input[type='range'] {
  /* removing default appearance */
  appearance: none;
  border-radius: 8px;
  background-color: var(--gray-color);
  &:hover {
    background-color: var(--gray-hover-color);
  }
}

input[type='range']::-webkit-slider-thumb {
  /* removing default appearance */
  appearance: none;
  /* creating a custom design */
  height: 20px;
  width: 20px;
  background-color: var(--info-color);
  border-radius: 50%;
  border: 2px solid var(--white-color);
}

.input-container {
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
}

.arrow-svg {
  height: 27px;
  width: 27px;
}