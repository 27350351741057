.MuiTableCell-root {
  border-bottom: none !important;
}

.MuiTableContainer-root {
  overflow: hidden !important;
}

.MuiTable-root {
  width: 'max-content' !important;
  margin-left: 0 !important;
}

.MuiTableRow-root {
  display: flex !important;
  padding-left: 5px !important;
  height: 2rem !important;
}

.first-cell {
  min-width: 6rem !important;
  display: flex !important;
  padding-left: 0 !important;
  gap: 1rem !important;
  padding-right: 0 !important;
}

.icon-wrapper {
  width: 16px;
  height: 16px;
}

.icon-wrapper > * {
  width: 100%;
  height: 100%;
}

.patient-info-table {
  font-weight: 500 !important;
}
